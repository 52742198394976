export function getTodayDateStringForUserTimezone() {
  // Create a new Date object for the current time in the user's timezone
  const now = new Date()

  // Extract the date and time components using toLocaleString for user's timezone
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')

  // Format the date as YYYY-MM-DDTHH:MM
  return `${year}-${month}-${day}T${hours}:${minutes}`
}

export function convertToLocaleTime(utcTime?: string): string {
  if (!utcTime) return ''
  const date = new Date(utcTime)

  // return the date with the day and time only
  return date.toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
}

export function convertToLocaleDate(utcTime?: string): string {
  if (!utcTime) return ''
  const date = new Date(utcTime)
  return date.toLocaleDateString()
}

export function formatDateForInput(dateString?: string) {
  if (!dateString) return ''
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export function formatISOToLocalDatetime(isoString: string | undefined) {
  if (!isoString) return ''
  const date = new Date(isoString)
  const offset = date.getTimezoneOffset()
  const localDate = new Date(date.getTime() - offset * 60 * 1000)
  return localDate.toISOString().slice(0, 16)
}

export function formatISOToDate(isoString: string | undefined) {
  if (!isoString) return ''
  const date = new Date(isoString)
  const offset = date.getTimezoneOffset()
  const localDate = new Date(date.getTime() - offset * 60 * 1000)
  return localDate.toISOString().slice(0, 10) // Extracts 'YYYY-MM-DD'
}

export const formatDateForDatabase = (dateString: string | undefined) => {
  if (!dateString) return ''
  const date = new Date(dateString)
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0]
}
