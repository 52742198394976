import React from 'react'
import TemplatesTable from '../components/Tables/TemplatesTable'
import { TemplateContextProvider } from '../contexts/templateContext'

export default function Templates() {
  return (
    <TemplateContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <TemplatesTable />
      </div>
    </TemplateContextProvider>
  )
}
