import { NylasScheduling } from '@nylas/react'

export default function NewAppointment() {
  const urlParams = new URLSearchParams(window.location.search)
  const configId = urlParams.get('config_id') || ''

  return (
    <NylasScheduling
      configurationId={configId}
      schedulerApiUrl="https://api.us.nylas.com"
    />
  )
}
