import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { DAIRequest } from '../utils/DAIRequest'
import { useNotifications } from '../contexts/notificationContext'
import { AppRoutes } from '../utils/constants'

type PasswordForm = {
  password: string
  confirmPassword: string
}

export default function Register() {
  const { register, handleSubmit } = useForm<PasswordForm>()
  const { addNotification } = useNotifications()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const name = searchParams.get('name')
  const token = searchParams.get('token')

  const submitMutation = useMutation({
    mutationFn: async (data: PasswordForm) => {
      await DAIRequest('post', '/api/v1/authentication/createInvitedUser', {
        body: {
          token,
          password: data.password,
        },
      })
    },
    onSuccess: () => {
      addNotification('success', 'User created successfully')
      navigate(AppRoutes.login)
    },
    onError: error => {
      addNotification('error', 'Error creating invited user')
    },
  })

  const onSubmit = async (data: PasswordForm) => {
    submitMutation.mutate(data)
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-36">
          <div className={'flex flex-row justify-center'}>
            <img
              className="h-14 w-auto"
              src={'/images/logo.svg'}
              alt="DeliverAI"
            />
          </div>

          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Register
          </h2>
          <p className="text-sm text-gray-500 mt-4">
            Welcome to DeliverAI{' '}
            {(name ?? '').slice(0, 1).toUpperCase() + (name ?? '').slice(1)}!
            Please create a password to complete your registration.
          </p>
        </div>

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  {...register('password')}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="cPassword"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="cPassword"
                  type="password"
                  autoComplete="current-password"
                  {...register('confirmPassword')}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
