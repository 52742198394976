import { BrowserRouter } from 'react-router-dom'
import { createClient, UsermavenProvider } from '@usermaven/react'
import { NylasProvider } from '@nylas/nylas-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from '../App'
import React from 'react'
import { AuthContextProvider } from './authContext'
import { NotificationProvider } from './notificationContext'

const usermavenClient = createClient({
  tracking_host: 'https://lians.deliverai.io',
  key: 'UMm3FQvxds',
})
const queryClient = new QueryClient()

export default function ContextFactory() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <UsermavenProvider client={usermavenClient}>
          <NotificationProvider>
            <AuthContextProvider>
              <App />
            </AuthContextProvider>
          </NotificationProvider>
        </UsermavenProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
