import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

export type NotificationType = 'success' | 'error' | 'warning' | 'info'

type Notification = {
  id: number
  type: NotificationType
  message: string
  callback?: () => void
}

type NotificationContextType = {
  notifications: Notification[]
  addNotification: (
    type: NotificationType,
    message: string,
    callback?: () => void
  ) => void
  removeNotification: (id: number) => void
  removeAllNotifications: () => void
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
)

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [idCounter, setIdCounter] = useState(0)

  const addNotification = (
    type: NotificationType,
    message: string,
    callback?: () => void
  ) => {
    const id = idCounter + 1
    setIdCounter(id)
    setNotifications(prev => [...prev, { id, type, message, callback }])
    // if (callback) return - TODO uncomment in production
    setTimeout(() => removeNotification(id), 5000)
  }

  const removeNotification = (id: number) => {
    setNotifications(prev =>
      prev.filter(notification => notification.id !== id)
    )
  }

  const removeAllNotifications = () => {
    setNotifications([])
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
        removeAllNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotifications = () => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error(
      'useNotifications must be used within a NotificationProvider'
    )
  }
  return context
}
