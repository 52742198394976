import { AppRoutes } from './constants'

export const showSearch = (location: string) => {
  if (
    location.includes(AppRoutes.editTemplate) ||
    location.includes(AppRoutes.contactProfile) ||
    location.includes(AppRoutes.contactProfile) ||
    location.includes(AppRoutes.settings) ||
    location.includes(AppRoutes.appointments) ||
    location.includes(AppRoutes.updateAutomationRoot) ||
    location.includes(AppRoutes.editTemplateRoot)
  ) {
    return false
  } else if (location !== AppRoutes.home) {
    return true
  }
  return false
}
