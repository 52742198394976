import { useContext } from 'react'
import AutomationContext from '../contexts/automationContext'

export default function useAutomationContext() {
  const contextValues = useContext(AutomationContext)

  if (!contextValues)
    throw new Error(
      'useAutomationContext must be used within a AutomationsContextProvider wrapped around the App'
    )

  return contextValues
}
