import SaleContext from '../contexts/saleContext'
import { useContext } from 'react'

export default function useSaleContext() {
  const contextValues = useContext(SaleContext)

  if (!contextValues)
    throw new Error(
      'useSaleContext must be used within a SaleContextProvider wrapped around the App'
    )

  return contextValues
}
