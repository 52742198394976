import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import useAuthContext from '../../hooks/useAuthContext'
import { timezones } from '../../utils/constants'
import { useMutation } from '@tanstack/react-query'
import { useNotifications } from '../../contexts/notificationContext'
import { DAIRequest } from '../../utils/DAIRequest'

type basicInfo = {
  firstName: string
  lastName: string
  timezone: string
}

export default function UpdateInfoSection() {
  const { user } = useAuthContext()
  const { addNotification } = useNotifications()

  const { register, handleSubmit, reset, formState } = useForm<basicInfo>({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      timezone: user?.timezone || '',
    },
  })

  useEffect(() => {
    if (user) {
      reset({
        firstName: user.firstName,
        lastName: user.lastName,
        timezone: user.timezone,
      })
    }
  }, [user, reset])

  const fileInputRef = useRef(null)

  const handleButtonClick = () => {
    // @ts-ignore
    fileInputRef.current!.click()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    const file = event.target.files[0]
    if (file) {
      // Handle the file upload here
      console.log(file)
    }
  }

  const handleUpdateInfoMutation = useMutation({
    mutationFn: (data: basicInfo) => {
      return DAIRequest('PATCH', '/api/v1/users/updateUser', {
        body: data,
      })
    },
    onSuccess: () => {
      addNotification('success', 'Info updated successfully')
    },
    onError: (error: Error) => {
      addNotification('error', error.message)
    },
  })

  const handleUpdateInfo = async (data: basicInfo) => {
    if (formState.isSubmitting) return
    handleUpdateInfoMutation.mutate(data)
  }

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 pt-2 pb-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Personal Information
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Make sure everything is up to date and modify as needed.
        </p>
      </div>

      <form className="md:col-span-2" onSubmit={handleSubmit(handleUpdateInfo)}>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <button
            type="button"
            onClick={handleButtonClick}
            className="col-span-full flex items-center gap-x-8"
          >
            <img
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
              className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
            />
            <div>
              <div className="text-left rounded-md bg-white/10 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white/20">
                Change Logo
              </div>
              <p className="mt-2 text-xs leading-5 text-gray-400">
                JPG, GIF or PNG. 1MB max.
              </p>
            </div>
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              First name
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="first-name"
                autoComplete="given-name"
                {...register('firstName')}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Last name
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="last-name"
                {...register('lastName')}
                autoComplete="family-name"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="col-span-full">
            <label
              htmlFor="timezone"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Timezone (This is on the organization level)
              <p className="mt-1 text-sm leading-6 text-gray-400 font-normal">
                This will change the timezone for the entire organization. It is
                also used to schedule emails and appointments. Thus it is
                important to set it correctly.
              </p>
            </label>
            <div className="mt-2">
              <select
                id="timezone"
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                {...register('timezone')}
              >
                {timezones.map(timezone => {
                  return (
                    <option value={timezone.timezone}>
                      {timezone.offset}: {timezone.timezone.replace('_', ' ')}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="mt-8 flex">
          <button
            type="submit"
            className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}
