import { Navigate, Outlet } from 'react-router-dom'
import React from 'react'
import useAuthContext from '../hooks/useAuthContext'
import { AppRoutes } from '../utils/constants'
import Loading from '../components/Loading'

export const PrivateRoute = () => {
  const { isAuthenticated, isLoading } = useAuthContext()

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loading />
      </div>
    )
  }

  return (
    <>{!isAuthenticated ? <Navigate to={AppRoutes.login} /> : <Outlet />}</>
  )
}
