import { Fragment, useCallback, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
  EllipsisHorizontalIcon,
  PlusSmallIcon,
} from '@heroicons/react/20/solid'
import { classNames } from '../utils/className'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../utils/constants'
import { convertToLocaleTime } from '../utils/timezone'
import { DAIRequest } from '../utils/DAIRequest'
const stats = [
  {
    name: 'Total Customers',
    value: '',
    change: '',
    changeType: '',
    link: AppRoutes.contacts,
  },
  {
    name: 'Sales Revenue',
    value: '',
    change: '',
    changeType: '',
    link: AppRoutes.sales,
  },
  {
    name: 'Emails Sent',
    value: '24,988',
    change: '-1.39%',
    changeType: 'positive',
    link: AppRoutes.automations,
  },
  {
    name: 'Appointments Booked',
    value: '1056',
    change: '+10.18%',
    changeType: 'negative',
    link: AppRoutes.appointments,
  },
]
const statuses = {
  Paid: 'text-green-700 bg-green-50 ring-green-600/20',
  Withdraw: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Overdue: 'text-red-700 bg-red-50 ring-red-600/10',
}

type Client = {
  contact: {
    id: string
    name: string
  }
  cost: number
  invoiceNumber: string
  createdAt: string
}

type buttonType = 'Last 7 days' | 'Last 30 days' | 'All Time' | 'Custom'

type Purchase = {
  contactId: string
  cost: number
  createdAt: string
  id: string
  invoiceNumber: string
  item: string
  productCategoryId: string
  userId: string
  contact: {
    name: string
  }
}

export default function Home() {
  const navigate = useNavigate()
  const currentDate = new Date()
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7))
  const thirtyDaysAgo = new Date(
    currentDate.setDate(currentDate.getDate() - 30)
  )
  const allTime = new Date('0001-01-01')
  const [currentButton, setCurrentButton] = useState<buttonType>('Last 7 days')
  const [dateRange, setDateRange] = useState({
    startDate: sevenDaysAgo,
    endDate: currentDate,
  })
  const [basicStats, setBasicStats] = useState(stats)
  const [recentPurchases, setRecentPurchases] = useState<Purchase[]>([])
  const [clients, setClients] = useState<Client[]>([])

  const handleDateRangeChange = useCallback(
    (button: buttonType) => {
      setCurrentButton(button)
      switch (button) {
        case 'Last 7 days':
          setDateRange({
            startDate: sevenDaysAgo,
            endDate: currentDate,
          })
          break
        case 'Last 30 days':
          setDateRange({
            startDate: thirtyDaysAgo,
            endDate: currentDate,
          })
          break
        case 'All Time':
          setDateRange({
            startDate: allTime,
            endDate: currentDate,
          })
          break
        case 'Custom':
          break
      }
    },
    [currentDate, sevenDaysAgo, thirtyDaysAgo, allTime]
  )

  useEffect(() => {
    const params = {
      start_date: dateRange.startDate.toISOString().split('T')[0],
      end_date: dateRange.endDate.toISOString().split('T')[0],
    }
    DAIRequest('GET', '/api/v1/dashboard/', {
      params,
    })
      .then(res => {
        const data = res.data

        setRecentPurchases(data?.recentPurchases)
        setClients(data?.topContacts)

        setBasicStats(prevStats =>
          prevStats.map(stat => {
            switch (stat?.name) {
              case 'Total Customers':
                return {
                  ...stat,
                  value: `${data?.totalContactsCount}`,
                  change: `${data?.contactsPercentageChange}%`,
                  changeType:
                    data?.contactsPercentageChange > 0
                      ? 'positive'
                      : 'negative',
                }
              case 'Sales Revenue':
                return {
                  ...stat,
                  value: `$${data?.totalRevenue?.toFixed(2) ?? 0}`,
                  change: `${data?.revenuePercentageChange}%`,
                  changeType:
                    data.revenuePercentageChange > 0 ? 'positive' : 'negative',
                }
              // case 'Emails Sent':
              //   return {
              //     ...stat,
              //     value: `${data.totalEmailsSent}`,
              //     change: `${data.emailsSentPercentageChange}%`,
              //     changeType:
              //       data.emailsSentPercentageChange > 0
              //         ? 'positive'
              //         : 'negative',
              //   }
              // case 'Appointments Booked':
              //   return {
              //     ...stat,
              //     value: `${data.totalAppointmentsBooked}`,
              //     change: `${data.appointmentsBookedPercentageChange}%`,
              //     changeType:
              //       data.appointmentsBookedPercentageChange > 0
              //         ? 'positive'
              //         : 'negative',
              //   }
              default:
                return stat
            }
          })
        )
      })
      .catch(err => {})
  }, [dateRange])

  const handleClick = useCallback(() => {
    navigate('/dashboard/sales?new=true')
  }, [])

  return (
    <>
      <div className="relative isolate overflow-hidden">
        {/*Secondary navigation*/}
        <header className="pb-4 sm:pb-6">
          <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
            <h1 className="text-base font-semibold leading-7 text-gray-900">
              Statistics
            </h1>
            <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
              <button
                className={
                  currentButton === 'Last 7 days'
                    ? 'text-blue-600'
                    : 'text-gray-700'
                }
                onClick={() => {
                  handleDateRangeChange('Last 7 days')
                }}
              >
                Last 7 days
              </button>
              <button
                className={
                  currentButton === 'Last 30 days'
                    ? 'text-blue-600'
                    : 'text-gray-700'
                }
                onClick={() => {
                  handleDateRangeChange('Last 30 days')
                }}
              >
                Last 30 days
              </button>
              <button
                className={
                  currentButton === 'All Time'
                    ? 'text-blue-600'
                    : 'text-gray-700'
                }
                onClick={() => {
                  handleDateRangeChange('All Time')
                }}
              >
                All Time
              </button>
            </div>
            <button
              onClick={handleClick}
              className="ml-auto flex items-center gap-x-1 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
              New Sale
            </button>
          </div>
        </header>
        {/* Stats */}
        <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
          <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
            {basicStats.map((stat, statIdx) => (
              <div
                onClick={() => {
                  navigate(stat.link)
                }}
                key={stat?.name}
                className={classNames(
                  statIdx % 2 === 1
                    ? 'sm:border-l'
                    : statIdx === 2
                      ? 'lg:border-l'
                      : '',
                  'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8 cursor-pointer'
                )}
              >
                <dt className="text-sm font-medium leading-6 text-gray-500">
                  {stat?.name}
                </dt>
                <dd
                  className={classNames(
                    stat.changeType === 'negative'
                      ? 'text-rose-600'
                      : 'text-gray-700',
                    'text-xs font-medium'
                  )}
                >
                  {stat.change}
                </dd>
                <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div
          className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          aria-hidden="true"
        >
          <div
            className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
            style={{
              clipPath:
                'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
            }}
          />
        </div>
      </div>

      <div className="space-y-16 py-16 xl:space-y-20">
        {/* Recent activity table */}
        <div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
              Recent activity
            </h2>
          </div>
          <div className="mt-6 overflow-hidden border-t border-gray-100">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <table className="w-full text-left">
                  <thead className="sr-only">
                    <tr>
                      <th>Amount</th>
                      <th className="hidden sm:table-cell">Client</th>
                      <th>More details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Fragment>
                      <tr className="text-sm leading-6 text-gray-900">
                        <th
                          scope="colgroup"
                          colSpan={3}
                          className="relative isolate py-2 font-semibold"
                        >
                          <time>Today</time>
                          <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                          <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                        </th>
                      </tr>
                      {recentPurchases.map(purchase => (
                        <tr key={purchase?.id}>
                          <td className="relative py-5 pr-6">
                            <div className="flex gap-x-6">
                              {/*<transaction.icon*/}
                              {/*  className="hidden h-6 w-5 flex-none text-gray-400 sm:block"*/}
                              {/*  aria-hidden="true"*/}
                              {/*/>*/}
                              <div className="flex-auto">
                                <div className="flex items-start gap-x-3">
                                  <div className="text-sm font-medium leading-6 text-gray-900">
                                    {purchase.cost}
                                  </div>
                                  <div
                                    className={classNames(
                                      // statuses[transaction.status as StatusKey],
                                      'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                    )}
                                  >
                                    Paid
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                            <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                          </td>
                          <td className="hidden py-5 pr-6 sm:table-cell">
                            <div className="text-sm leading-6 text-gray-900">
                              {purchase.contact?.name}
                            </div>
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                              {purchase.item}
                            </div>
                          </td>
                          <td className="py-5 text-right">
                            <div className="flex justify-end">
                              <a
                                // href={transaction.href}
                                className="text-sm font-medium leading-6 text-blue-600 hover:text-blue-500"
                              >
                                View
                                <span className="hidden sm:inline">
                                  {' '}
                                  transaction
                                </span>
                                <span className="sr-only">
                                  , invoice #{purchase.invoiceNumber},{' '}
                                  {/*{transaction.client}*/}
                                </span>
                              </a>
                            </div>
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                              Invoice{' '}
                              <span className="text-gray-900">
                                #{purchase.invoiceNumber}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Recent client list*/}
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="flex items-center justify-between">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Recent customers
              </h2>
              <a
                href="#"
                className="text-sm font-semibold leading-6 text-blue-600 hover:text-blue-500"
              >
                View all<span className="sr-only">, customers</span>
              </a>
            </div>
            <ul
              role="list"
              className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
            >
              {clients?.map(client => (
                <li
                  key={client.contact?.id}
                  className="overflow-hidden rounded-xl border border-gray-200"
                >
                  <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                    <div className="text-sm font-medium leading-6 text-gray-900">
                      {client.contact?.name}
                    </div>
                    <Menu as="div" className="relative ml-auto">
                      <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Open options</span>
                        <EllipsisHorizontalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                              >
                                View
                                <span className="sr-only">
                                  , {client.contact?.name}
                                </span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                              >
                                Edit
                                <span className="sr-only">
                                  , {client.contact?.name}
                                </span>
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500">Last Sale</dt>
                      <dd className="text-gray-700">
                        <time dateTime={convertToLocaleTime(client.createdAt)}>
                          {convertToLocaleTime(client.createdAt)}
                        </time>
                      </dd>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500">Amount</dt>
                      <dd className="flex items-start gap-x-2">
                        <div className="font-medium text-gray-900">
                          {client.cost}
                        </div>
                      </dd>
                    </div>
                  </dl>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
