import { createContext, PropsWithChildren, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNotifications } from './notificationContext'
import { useUsermaven } from '@usermaven/react'
import {
  Mutation,
  useMutation,
  UseMutationResult,
  useQuery,
} from '@tanstack/react-query'
import { DAIRequest } from '../utils/DAIRequest'
import { Note } from '../types/Note'
import { Sale } from '../types/Sale'
import { PersonSimple } from '../types/Person'
import { ProductSimple } from '../types/Product'

type TemplateContextProps = {
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
  updateProfileMutation: UseMutationResult<any, Error, ProfileData, unknown>
  profileData?: ProfileData
  refetchProfileData: () => void
  notesData?: Note[]
  createNoteMutation: UseMutationResult<any, Error, CreateNote, unknown>
  updateNoteMutation: UseMutationResult<any, Error, UpdateNote, unknown>
  deleteNoteMutation: UseMutationResult<any, Error, string, unknown>
  refetchNotes: () => void
  sales?: Sale[]
  refetchSales: () => void
  isLoading: boolean
  contacts: PersonSimple[]
  products: ProductSimple[]
  editSale: (data: Sale) => void
  createSale: (data: Sale) => void
}

const ProfileContext = createContext<TemplateContextProps | undefined>(
  undefined
)

export type ProfileData = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  anniversary?: string
  birthday?: string
}

export type CreateNote = {
  content: string
  contactId: string
}

export type UpdateNote = {
  id: string
  content: string
  contactId: string
}

export const ProfileContextProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams()
  const { addNotification } = useNotifications()
  const { track } = useUsermaven()
  const [isEditing, setIsEditing] = useState(false)

  const { data: contacts, isLoading: contactsLoading } = useQuery<
    PersonSimple[]
  >({
    queryKey: ['contact', 'basic'],
    queryFn: async () => {
      return (await DAIRequest('get', '/api/v1/contacts/basic')).data
    },
  })

  const { data: products, isLoading: productsLoading } = useQuery<
    ProductSimple[]
  >({
    queryKey: ['product-categories', 'basic'],
    queryFn: async () => {
      return (
        await DAIRequest(
          'get',
          '/api/v1/product-categories/getMyProductCategoriesBasic'
        )
      ).data
    },
  })

  const createSale = useMutation({
    mutationFn: async (data: Sale) => {
      return await DAIRequest('post', '/api/v1/purchases/createPurchase', {
        body: data,
      })
    },
    onSuccess: async () => {
      await track('Sale_Created')
      await refetchSales()
      addNotification('success', 'Sale created successfully')
    },
    onError: () => {
      addNotification('error', 'Error creating sale')
    },
  })

  const editSale = useMutation({
    mutationFn: async (data: Sale) => {
      return await DAIRequest('patch', '/api/v1/purchases/updatePurchase/:id', {
        body: data,
        params: { id: data.id },
      })
    },
    onSuccess: async () => {
      await track('Sale_Edited')
      await refetchSales()
      addNotification('success', 'Sale edited successfully')
    },
    onError: async () => {
      await track('Sale_Edited_Error')
      addNotification('error', 'Error editing sale')
    },
  })

  const {
    data: profileData,
    refetch: refetchProfileData,
    isLoading: profileDataIsLoading,
  } = useQuery<ProfileData>({
    queryKey: ['profile', id],
    queryFn: async () => {
      return DAIRequest('get', '/api/v1/contacts/:id', {
        params: { id: id! },
      }).then(res => res.data)
    },
    enabled: !!id,
  })

  const updateProfileMutation = useMutation({
    mutationFn: async (data: ProfileData) => {
      data.anniversary = new Date(data.anniversary!).toISOString()
      data.birthday = new Date(data.birthday!).toISOString()
      return DAIRequest('patch', '/api/v1/contacts/updateContact/:id', {
        body: data,
        params: { id: id! },
      }).then(res => res.data)
    },
    onSuccess: async () => {
      await refetchProfileData()
      await track('Profile_Updated')
      addNotification('success', 'Profile updated successfully')
      setIsEditing(!isEditing)
    },
    onError: async () => {
      await track('Profile_Update_Failed')
      addNotification('error', 'Profile update failed')
      setIsEditing(!isEditing)
    },
  })

  const {
    data: sales,
    refetch: refetchSales,
    isLoading: salesIsLoading,
  } = useQuery<Sale[]>({
    queryKey: ['profile', 'sales'],
    queryFn: async () => {
      return (
        await DAIRequest(
          'GET',
          '/api/v1/purchases/getAllPurchasesByContactId/:id',
          {
            params: { id: id! },
          }
        )
      ).data
    },
  })

  // crud operations for notes
  const {
    data: notesData,
    refetch: refetchNotes,
    isLoading: notesIsLoading,
  } = useQuery<Note[]>({
    queryKey: ['notes', id],
    queryFn: async () => {
      return DAIRequest('get', '/api/v1/notes/contact/:id', {
        params: { id: id! },
      }).then(res => res.data)
    },
    enabled: !!id,
  })

  const createNoteMutation = useMutation({
    mutationFn: async (data: CreateNote) => {
      return DAIRequest('post', '/api/v1/notes/', {
        body: data,
      }).then(res => res.data)
    },
    onSuccess: async () => {
      await refetchNotes()
      await track('Note_Created')
      addNotification('success', 'Note created successfully')
    },
    onError: async () => {
      await track('Note_Create_Failed')
      addNotification('error', 'Note creation failed')
    },
  })

  const updateNoteMutation = useMutation({
    mutationFn: async (data: UpdateNote) => {
      return DAIRequest('patch', '/api/v1/notes/:id', {
        body: data,
        params: { id: data.id },
      }).then(res => res.data)
    },
    onSuccess: async () => {
      await refetchNotes()
      await track('Note_Updated')
      addNotification('success', 'Note updated successfully')
    },
    onError: async () => {
      await track('Note_Update_Failed')
      addNotification('error', 'Note update failed')
    },
  })

  const deleteNoteMutation = useMutation({
    mutationFn: async (id: string) => {
      return DAIRequest('delete', '/api/v1/notes/:id', {
        params: { id },
      }).then(res => res.data)
    },
    onSuccess: async () => {
      await refetchNotes()
      await track('Note_Deleted')
      addNotification('success', 'Note deleted successfully')
    },
    onError: async () => {
      await track('Note_Delete_Failed')
      addNotification('error', 'Note deletion failed')
    },
  })

  const isLoading = useMemo(() => {
    return (
      profileDataIsLoading ||
      notesIsLoading ||
      salesIsLoading ||
      updateProfileMutation.isPending ||
      createNoteMutation.isPending ||
      updateNoteMutation.isPending ||
      deleteNoteMutation.isPending ||
      contactsLoading ||
      productsLoading
    )
  }, [
    profileDataIsLoading,
    notesIsLoading,
    salesIsLoading,
    updateProfileMutation.isPending,
  ])

  return (
    <ProfileContext.Provider
      value={{
        isEditing,
        setIsEditing,
        updateProfileMutation,
        profileData,
        notesData,
        sales,
        refetchProfileData,
        refetchNotes,
        refetchSales,
        isLoading,
        createNoteMutation,
        updateNoteMutation,
        deleteNoteMutation,
        contacts: contacts ?? [],
        products: products ?? [],
        editSale: editSale.mutate,
        createSale: createSale.mutate,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export default ProfileContext
