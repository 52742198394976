import React from 'react'
import TeamTable from '../components/Tables/TeamTable'

export default function TeamSettings() {
  return (
    <div>
      <div className="px-4 sm:px-6 lg:px-8">
        <TeamTable />
      </div>
    </div>
  )
}
