import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { DAIRequest } from '../../utils/DAIRequest'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from '../../contexts/notificationContext'
import Loading from '../Loading'

type accountLink = {
  object: string
  url: string
}

type accountInfo = {
  isAuthorized: boolean
  integrationEmail: string
  provider: Provider
}

enum Provider {
  google = 'google',
  microsoft = 'microsoft',
  imap = 'imap',
}

export default function UnipileIntegrationSection() {
  const navigate = useNavigate()
  const { addNotification } = useNotifications()

  const { mutate: getAccountLink } = useMutation<accountLink>({
    mutationFn: async () => {
      return (await DAIRequest('GET', '/api/v1/unipile/generateAccountLink'))
        .data
    },
    onSuccess: data => {
      window.location.href = data.url
    },

    onError: error => {
      addNotification('error', 'Error' + error.message)
    },
  })

  const { data, isLoading, refetch } = useQuery<accountInfo>({
    queryKey: ['accountInfo'],
    queryFn: async () => {
      return (await DAIRequest('GET', '/api/v1/unipile/getAccountInfo')).data
    },
  })

  const { mutate: removeAccount } = useMutation({
    mutationFn: async () => {
      return await DAIRequest('POST', '/api/v1/unipile/removeAccount')
    },
    onSuccess: () => {
      addNotification('success', 'Email provider disconnected.')
      refetch()
    },
    onError: error => {
      addNotification('error', 'Error' + error.message)
    },
  })

  if (isLoading) {
    return <Loading full={true} />
  }

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 pt-2 pb-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Integrate your email provider.
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          This is how we send emails on your behalf, schedule appointments into
          your calendar, and more.
        </p>
      </div>

      <div className="md:col-span-2">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email Provider
            </label>
            <div className="mt-2">
              {!data?.isAuthorized && (
                <p className="text-sm leading-6 text-gray-400">
                  No email provider connected.
                </p>
              )}
              {data?.isAuthorized && (
                <div className="flex items-center gap-x-4">
                  {data?.provider === Provider.google && (
                    <img
                      src={'/images/googleLogo.png'}
                      alt="Google Logo"
                      className="w-20 h-auto"
                    />
                  )}
                  {data?.provider === Provider.microsoft && (
                    <img
                      src={'/images/microsoftLogo.png'}
                      alt="Microsoft Logo"
                      className="w-20 h-auto"
                    />
                  )}
                  {data?.provider === Provider.imap && (
                    <span className="text-sm font-semibold text-gray-900">
                      IMAP
                    </span>
                  )}
                  <span className="text-sm font-semibold text-gray-900">
                    Connected
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-8 flex">
          <button
            onClick={() => getAccountLink()}
            type="button"
            className="mr-4 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {!data?.isAuthorized && 'Connect Email Account'}
            {data?.isAuthorized && 'Change Email Account'}
          </button>
          {/*TODO - If data.isAuthorized is true, but has been disconnected, the button should say "Reconnect Email Account"*/}
          {data?.isAuthorized && (
            <button
              onClick={() => removeAccount()}
              type="button"
              className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Disconnect
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
