import { classNames } from '../../utils/className'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loading from '../Loading'
import useAutomationContext from '../../hooks/useAutomationContext'
import { convertToLocaleDate } from '../../utils/timezone'
import CreateAutomationSidePanel from '../SidePanels/CreateAutomationSidePanel'
import UseAIModal from '../Modals/UseAIModal'
import { useUsermaven } from '@usermaven/react'

export default function AutomationTable() {
  const { loading, automations } = useAutomationContext()
  const navigate = useNavigate()
  const { track } = useUsermaven()

  const [modalOpen, setModalOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)

  const openAIPopup = useCallback(async () => {
    setModalOpen(true)
    await track('AI_Popup_Opened')
  }, [])

  const createNew = useCallback(() => {
    // navigate('/dashboard/automations/update/0') // TODO bring this back when need to be fancy
    setCreateOpen(true)
  }, [navigate])

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <UseAIModal
        openModal={() => setModalOpen(true)}
        closeModal={() => setModalOpen(false)}
        isOpen={modalOpen}
      />
      {createOpen && (
        <CreateAutomationSidePanel
          onClose={() => {
            setCreateOpen(false)
          }}
        />
      )}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            All Automations
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all automations in your account.
          </p>
        </div>
        <div className="hidden md:ml-4 md:flex md:items-center">
          <button
            onClick={openAIPopup}
            type="button"
            className="ml-6 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Use AI
          </button>
          <div className="ml-6 h-6 w-px bg-gray-300" />
          <button
            type="button"
            className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={createNew}
          >
            New
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Automation Name
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    Created At
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Updated At
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {automations?.map((automation, i) => (
                  <tr key={automation.id}>
                    <td
                      className={classNames(
                        i !== automations.length - 1
                          ? 'border-b border-gray-200'
                          : '',
                        'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                      )}
                    >
                      {automation.name}
                    </td>
                    <td
                      className={classNames(
                        i !== automations.length - 1
                          ? 'border-b border-gray-200'
                          : '',
                        'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell'
                      )}
                    >
                      {convertToLocaleDate(automation.createdAt)}
                    </td>
                    <td
                      className={classNames(
                        i !== automations.length - 1
                          ? 'border-b border-gray-200'
                          : '',
                        'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'
                      )}
                    >
                      {convertToLocaleDate(automation.updatedAt)}
                    </td>
                    <td
                      className={classNames(
                        i !== automations.length - 1
                          ? 'border-b border-gray-200'
                          : '',
                        'relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8'
                      )}
                    >
                      <button
                        onClick={() =>
                          navigate(
                            `/dashboard/automations/update/${automation.id}`
                          )
                        }
                        className="text-blue-600 hover:text-blue-900"
                      >
                        Edit
                        <span className="sr-only">, {automation.name}</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
