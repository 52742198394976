import React from 'react'
import SalesTable from '../components/Tables/SalesTable'
import { SaleContextProvider } from '../contexts/saleContext'

export default function Sales() {
  return (
    <SaleContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <SalesTable />
      </div>
    </SaleContextProvider>
  )
}
