import { NylasSchedulerEditor } from '@nylas/react'
import { ModalProps } from '../../types/ModalProps'
import { Fragment, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function SchedulerEditor(props: ModalProps<any>) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        initialFocus={cancelButtonRef}
        onClose={props.closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/*<Dialog.Panel className="relative flex items-center justify-center transform overflow-hidden rounded-lg bg-white px-2 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-3xl lg:max-w-5xl xl:max-w-5xl sm:p-4">*/}
              <Dialog.Panel className="relative flex items-center justify-center transform overflow-hidden rounded-lg shadow-xl transition-all">
                <NylasSchedulerEditor
                  eventOverrides={{
                    createButtonClick: async () => {
                      // Any task that you want to perform
                    },
                  }}
                  schedulerPreviewLink={`${window.location.origin}/schedule?config_id={config.id}`}
                  nylasSessionsConfig={{
                    clientId: '272e8243-5c42-4bb4-a7ec-d630903356ab',
                    grantId: 'db81a2f9-1cc9-4e9f-93b6-fbebd81ff770',
                    redirectUri: `${window.location.origin}/dashboard/appointments`,
                    domain: 'https://api.us.nylas.com/v3',
                    hosted: true,
                    accessType: 'offline',
                  }}
                  defaultSchedulerConfigState={{
                    selectedConfiguration: {
                      requires_session_auth: false, // creates public configuration which does not require session tokens
                      scheduler: {
                        rescheduling_url: `${window.location.origin}/reschedule/:booking_ref`,
                        cancellation_url: `${window.location.origin}/cancel/:booking_ref`,
                      },
                    },
                  }}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
