import TemplateContext from '../contexts/templateContext'
import { useContext } from 'react'

export default function useTemplateContext() {
  const contextValues = useContext(TemplateContext)

  if (!contextValues)
    throw new Error(
      'useTemplateContext must be used within a TemplateContextProvider wrapped around the App'
    )

  return contextValues
}
