import AutomationTable from '../components/Tables/AutomationTable'
import React from 'react'
import { AutomationContextProvider } from '../contexts/automationContext'

export default function Automations() {
  return (
    <AutomationContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <AutomationTable />
      </div>
    </AutomationContextProvider>
  )
}
