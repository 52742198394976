import { Editor } from 'primereact/editor'
import { useEffect, useState } from 'react'
import Loading from '../components/Loading'
import { DAIRequest } from '../utils/DAIRequest'
import { useQuery } from '@tanstack/react-query'
import useTemplateContext from '../hooks/useTemplateContext'

export default function Edit() {
  const {
    createTemplateMutation,
    updateTemplateMutation,
    setTemplateId,
    template: data,
    templateLoading: isLoading,
    templateId,
  } = useTemplateContext()

  const [title, setTitle] = useState(data?.name)
  const [subject, setSubject] = useState(data?.subject)
  const [text, setText] = useState(data?.content)

  useEffect(() => {
    const id = window.location.pathname.split('/').pop()
    if (id !== undefined) {
      setTemplateId(id)
    }
  }, [])

  useEffect(() => {
    if (data) {
      setTitle(data.name)
      setSubject(data.subject)
      setText(data.content)
    }
  }, [data])

  const saveTemplate = () => {
    if (templateId === '0') {
      createTemplateMutation.mutate({
        name: title,
        subject,
        content: text,
      })
    } else {
      updateTemplateMutation.mutate({
        id: templateId,
        name: title,
        subject,
        content: text,
      })
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Edit Email Template
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-row justify-center">
          <div className="ml-4"></div>
          <button
            type="button"
            className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={saveTemplate}
          >
            Save
          </button>
        </div>
      </div>
      <div className={'pt-6'}>
        <input
          type="text"
          required={true}
          placeholder={'Template Title'}
          id="template-title"
          value={title}
          onChange={e => setTitle(e.target.value)}
          className="mb-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
        />
        <input
          type="text"
          required={true}
          placeholder={'Subject'}
          id="template-subject"
          value={subject}
          onChange={e => setSubject(e.target.value)}
          className="mb-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
        />
        <Editor
          value={text}
          showHeader={false}
          placeholder={'Enter your template here'}
          onTextChange={e => setText(e.textValue ?? '')}
          style={{ height: '525px', borderRadius: '6px' }}
        />
      </div>
    </div>
  )
}
