import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/Loading'
import { useForm } from 'react-hook-form'
import useAuthContext from '../hooks/useAuthContext'
import { LoginType } from '../contexts/authContext'
import { AppRoutes } from '../utils/constants'

export default function Login() {
  const navigate = useNavigate()
  const { isLoading, loginMutation } = useAuthContext()
  const { register, handleSubmit } = useForm<LoginType>()

  const onForgotPassword = useCallback(() => {
    navigate(AppRoutes.forgotPassword)
  }, [])

  const loginSubmit = useCallback(
    (data: LoginType) => {
      loginMutation.mutate(data)
    },
    [loginMutation]
  )

  if (isLoading || loginMutation.isPending) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <div className="flex min-h-screen flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-14 w-auto"
                src={'/images/logo.svg'}
                alt="DeliverAI"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Not a member?{' '}
                <a
                  href="https://deliverai.io/get-a-demo/"
                  className="font-semibold text-blue-600 hover:text-blue-500"
                >
                  Book a demo
                </a>
              </p>
            </div>

            <div className="mt-10">
              <div>
                <form
                  onSubmit={handleSubmit(loginSubmit)}
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        type="email"
                        {...register('email')}
                        autoComplete="email"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        type="password"
                        {...register('password')}
                        autoComplete="current-password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        {...register('rememberMe')}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-3 block text-sm leading-6 text-gray-700"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm leading-6">
                      <a
                        onClick={onForgotPassword}
                        className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <div className="image-container">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="/images/Jewelry_Store.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  )
}
