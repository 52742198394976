import ContactTable from '../components/Tables/ContactTable'
import React from 'react'
import { ContactContextProvider } from '../contexts/contactContext'
import SuccessNotification from '../components/Notifications/SuccessNotification'

export default function Contacts() {
  return (
    <ContactContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <ContactTable />
      </div>
    </ContactContextProvider>
  )
}
