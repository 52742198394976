import { createContext, PropsWithChildren, useMemo, useState } from 'react'
import { CreateTemplate, Template } from '../types/Template'
import { useUsermaven } from '@usermaven/react'
import useSearchContext from '../hooks/useSearchContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { DAIRequest } from '../utils/DAIRequest'
import { useNotifications } from './notificationContext'

type TemplateContextProps = {
  templates: Template[]
  createTemplateMutation: any // todo fix this type
  updateTemplateMutation: any // todo fix this type
  template: Template | undefined
  setTemplateId: (id: string) => void
  templateId: string
  templatesLoading: boolean
  templateLoading: boolean
  deleteTemplate: (id: string) => void
}

const TemplateContext = createContext<TemplateContextProps | undefined>(
  undefined
)

export const TemplateContextProvider = ({ children }: PropsWithChildren) => {
  const { track } = useUsermaven()
  const { searchQuery } = useSearchContext()
  const { addNotification } = useNotifications()

  const [templateId, setTemplateId] = useState('0')

  const {
    data: templatesState,
    isLoading: templatesLoading,
    refetch: refetchTemplates,
  } = useQuery<Template[]>({
    queryKey: ['templates'],
    queryFn: async () => {
      return DAIRequest('GET', '/api/v1/templates/getMyTemplates').then(
        res => res.data
      )
    },
  })

  const templates = useMemo(() => {
    if (!templatesState) {
      return []
    }

    if (!searchQuery) {
      return templatesState
    }
    return templatesState.filter(template =>
      template.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [templatesState, searchQuery])

  const createTemplateMutation = useMutation({
    mutationFn: async (template: CreateTemplate) => {
      return DAIRequest('POST', '/api/v1/templates/createTemplate', {
        body: template,
      })
    },
    onSuccess: async () => {
      addNotification('success', 'Template Created Successfully')
      await track('Template Created')
      await refetchTemplate()
    },
    onError: () => {
      addNotification('error', 'Failed to create template')
    },
  })

  const updateTemplateMutation = useMutation({
    mutationFn: async (template: Template) => {
      return DAIRequest('PATCH', '/api/v1/templates/updateTemplate/:id', {
        body: template,
        params: { id: templateId },
      })
    },
    onSuccess: async () => {
      addNotification('success', 'Template Updated Successfully')
      await track('Template Updated')
      await refetchTemplate()
    },
    onError: () => {
      addNotification('error', 'Failed to update template')
    },
  })

  const {
    isLoading: templateLoading,
    data: template,
    refetch: refetchTemplate,
  } = useQuery<Template>({
    queryKey: ['templates', templateId],
    queryFn: async () => {
      return DAIRequest('GET', `/api/v1/templates/:id`, {
        params: { id: templateId },
      }).then(res => res.data)
    },
    enabled: templateId !== '0',
  })

  const deleteTemplate = useMutation({
    mutationFn: async (id: string) => {
      return DAIRequest('DELETE', `/api/v1/templates/deleteTemplate/:id`, {
        params: { id },
      })
    },
    onSuccess: async () => {
      addNotification('success', 'Template Deleted Successfully')
      await track('Template_Deleted')
      await refetchTemplates()
    },
    onError: async () => {
      await track('Template_Deleted_Failed')
      addNotification('error', 'Failed to delete template')
    },
  })

  return (
    <TemplateContext.Provider
      value={{
        templatesLoading,
        templates,
        createTemplateMutation,
        updateTemplateMutation,
        template,
        setTemplateId,
        templateLoading,
        templateId,
        deleteTemplate: deleteTemplate.mutate,
      }}
    >
      {children}
    </TemplateContext.Provider>
  )
}

export default TemplateContext
