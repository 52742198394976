import { PencilIcon } from '@heroicons/react/24/outline'

import { useCallback, useState } from 'react'
import CreateNotesModal from './Modals/CreateNotesModal'
import { Note } from '../types/Note'
import { truncate } from '../utils/textUtils'
import useProfileContext from 'src/hooks/useProfileContext'
import { TrashIcon } from '@heroicons/react/24/solid'
import RemoveModal from './Modals/RemoveModal'

const Notes = () => {
  const { notesData: items, deleteNoteMutation } = useProfileContext()
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [initialData, setInitialData] = useState<Note | undefined>()
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined)

  const onClick = useCallback(() => {
    setInitialData(undefined)
    setModalOpen(true)
  }, [])

  const handleEditNote = useCallback((id: string) => {
    setInitialData(items?.find(item => item.id === id)!)
    setModalOpen(true)
  }, [])

  const handleDeleteNote = useCallback(
    (id: string) => {
      setDeleteId(id)
      setDeleteModalOpen(true)
    },
    [setDeleteId, setDeleteModalOpen]
  )

  return (
    <div>
      {modalOpen && (
        <CreateNotesModal
          open={modalOpen}
          setOpen={setModalOpen}
          onSubmit={() => {}}
          initialData={initialData}
        />
      )}
      {deleteModalOpen && (
        <RemoveModal
          isOpen={deleteModalOpen}
          openModal={() => setDeleteModalOpen(true)}
          closeModal={() => {
            setDeleteModalOpen(false)
            setDeleteId(undefined)
          }}
          title={'Delete Note'}
          text={'Are you sure you want to delete this note?'}
          onDelete={() => {
            deleteNoteMutation.mutate(deleteId!)
            setDeleteModalOpen(false)
          }}
          initialData={deleteId}
        />
      )}
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap pb-6">
        <div className="ml-4 mt-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Notes
          </h3>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">
          <button
            type="button"
            className="relative inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={onClick}
          >
            Create new note
          </button>
        </div>
      </div>
      <ul role="list" className="space-y-3 overflow-auto h-[300px]">
        {items?.length === 0 && (
          <span className={'text-gray-700'}>No Notes found</span>
        )}
        {items?.map(item => (
          <li
            key={item.id}
            className="group relative overflow-hidden rounded-md bg-white px-6 py-4 shadow ring-1 ring-gray-200 m-2"
          >
            <div className="absolute top-3 right-3">
              <button
                type="button"
                className="p-1 text-gray-400 hover:text-gray-600"
                aria-label="Edit"
                onClick={() => handleEditNote(item.id)}
              >
                <PencilIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="absolute top-3 right-9">
              <button
                type="button"
                className="p-1 text-gray-400 hover:text-gray-600"
                aria-label="Edit"
                onClick={() => handleDeleteNote(item.id)}
              >
                <TrashIcon
                  className="h-5 w-5 text-blue-500"
                  aria-hidden="true"
                />
              </button>
            </div>
            {/*<h4 className="text-base font-semibold leading-6 text-gray-900">*/}
            {/*  {item.title}*/}
            {/*</h4>*/}
            <p className="mt-1 text-sm text-gray-700">
              {truncate(item.content, 190)}
            </p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Notes
