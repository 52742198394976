import ProductContext from '../contexts/productContext'
import { useContext } from 'react'

export default function useProductContext() {
  const contextValues = useContext(ProductContext)

  if (!contextValues)
    throw new Error(
      'useProductContext must be used within a ProductContextProvider wrapped around the App'
    )

  return contextValues
}
