import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  PaperClipIcon,
  TagIcon,
  UserCircleIcon,
} from '@heroicons/react/20/solid'
import { classNames } from '../../utils/className'
import { UseFormRegisterReturn } from 'react-hook-form'

const assignees = [
  { name: 'Unassigned', value: null },
  {
    name: 'Wade Cooper',
    value: 'wade-cooper',
    avatar:
      'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  // More items...
]
const labels = [
  { name: 'Unlabelled', value: null },
  { name: 'Engineering', value: 'engineering' },
  // More items...
]
const dueDates = [
  { name: 'No due date', value: null },
  { name: 'Today', value: 'today' },
  // More items...
]

interface TextBoxInputProps {
  registerTitle?: UseFormRegisterReturn
  registerDescription: UseFormRegisterReturn
  handleCancel: () => void
  label?: string
  enableTitle?: boolean
}

export default function TextBox({
  registerTitle,
  registerDescription,
  handleCancel,
  label = 'Title',
  enableTitle = true,
}: TextBoxInputProps) {
  const [assigned, setAssigned] = useState(assignees[0])
  const [labelled, setLabelled] = useState(labels[0])
  const [dated, setDated] = useState(dueDates[0])

  return (
    <div className="relative">
      <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500">
        <div className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0" />
        {enableTitle && (
          <>
            <label htmlFor={label} className="sr-only">
              {label}
            </label>
            <input
              type="text"
              id={label}
              className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
              placeholder="Title"
              {...registerTitle}
            />
          </>
        )}
        <label htmlFor="description" className="sr-only">
          Description
        </label>
        <textarea
          rows={2}
          id="description"
          className="h-52 w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Write a Note..."
          {...registerDescription}
        />

        {/* Spacer element to match the height of the toolbar */}
        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-9" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
      </div>

      <div className="absolute inset-x-px bottom-0">
        <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <div className="flex">
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"*/}
            {/*>*/}
            {/*  <PaperClipIcon*/}
            {/*    className="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500"*/}
            {/*    aria-hidden="true"*/}
            {/*  />*/}
            {/*  <span className="text-sm italic text-gray-500 group-hover:text-gray-600">*/}
            {/*    Attach a file*/}
            {/*  </span>*/}
            {/*</button>*/}
          </div>
          <div className="flex-shrink-0 space-x-4">
            <button
              type="submit"
              className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
