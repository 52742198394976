import { Fragment, useCallback, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import TextBox from '../InputBoxes/TextBox'
import { Product } from '../../types/Product'
import { ModalProps } from '../../types/ModalProps'
import useProductContext from '../../hooks/useProductContext'

export default function CreateProductModal(props: ModalProps<Product>) {
  const cancelButtonRef = useRef(null)
  const { register, handleSubmit } = useForm<Product>({
    defaultValues: props.initialData,
  })
  const { editProduct, createProduct } = useProductContext()

  const onSubmit = useCallback(
    (data: Product) => {
      if (props.initialData) {
        editProduct(data)
      } else {
        createProduct(data)
      }
      props.closeModal()
    },
    [createProduct, editProduct, props.closeModal]
  )

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        initialFocus={cancelButtonRef}
        onClose={props.closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-xl lg:max-w-2xl xl:max-w-3xl sm:p-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextBox
                    registerDescription={register('description')}
                    registerTitle={register('name')}
                    handleCancel={props.closeModal}
                    label={'Subject'}
                  />
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
