import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  BuildingStorefrontIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  DocumentMinusIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  CogIcon,
  GlobeAmericasIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { classNames } from '../utils/className'
import useSearchContext from '../hooks/useSearchContext'
import { showSearch } from '../utils/showSearch'
import useAuthContext from '../hooks/useAuthContext'
import { AppRoutes } from '../utils/constants'

const DashboardLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { logout } = useAuthContext()
  const { searchQueryOnChange, searchQuery } = useSearchContext()
  const [ifSearch, setIfSearch] = useState(false)
  const [ifSecondary, setIfSecondary] = useState(false)

  useEffect(() => {
    setIfSearch(showSearch(location.pathname))
    if (location.pathname.includes(AppRoutes.settings)) {
      setIfSecondary(true)
    }
  }, [location])

  const userNavigation = [
    {
      name: 'Sign out',
      onClick: logout,
    },
  ]

  const onSettings = location.pathname.includes(AppRoutes.settings)

  const navigation = [
    {
      name: 'Dashboard',
      href: AppRoutes.home,
      icon: HomeIcon,
      current: location.pathname === '/dashboard',
    },
    {
      name: 'Contacts',
      href: AppRoutes.contacts,
      icon: UsersIcon,
      current: location.pathname.includes('/dashboard/contacts'),
    },
    //   TODO bring this back when we have implemented appointments
    // {
    //   name: 'Appointments',
    //   href: AppRoutes.appointments,
    //   icon: CalendarIcon,
    //   current: location.pathname.includes('/dashboard/appointments'),
    //   children: [
    //     { name: 'Create', href: '/dashboard/appointments' },
    //     { name: 'Edit', href: '/dashboard/appointments/edit' },
    //   ],
    // },
    {
      name: 'Products',
      href: AppRoutes.products,
      icon: BuildingStorefrontIcon,
      current: location.pathname === '/dashboard/products',
    },
    {
      name: 'Sales',
      href: AppRoutes.sales,
      icon: CurrencyDollarIcon,
      current: location.pathname.includes('/dashboard/sales'),
    },
    {
      name: 'Templates',
      href: AppRoutes.templates,
      icon: DocumentMinusIcon,
      current: location.pathname.includes('/dashboard/templates'),
    },
    {
      name: 'Automations',
      href: AppRoutes.automations,
      icon: CogIcon,
      current: location.pathname.includes('/dashboard/automations'),
    },
    {
      name: 'Website Builder',
      href: AppRoutes.websiteBuilder,
      icon: GlobeAmericasIcon,
      current: location.pathname.includes('/dashboard/website-builder'),
    },
  ]

  const secondaryNavigation = [
    {
      name: 'Account',
      href: AppRoutes.settings,
      current: location.pathname === AppRoutes.settings,
    },
    {
      name: 'Teams',
      href: AppRoutes.teamSettings,
      current: location.pathname.includes(AppRoutes.teamSettings),
    },
    {
      name: 'Integrations',
      href: AppRoutes.integrationSettings,
      current: location.pathname.includes(AppRoutes.integrationSettings),
    },
    {
      name: 'Billing',
      href: AppRoutes.billing,
      current: location.pathname.includes(AppRoutes.billing),
    },
  ]

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-blue-600 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={'/images/logo-white.svg'}
                        alt="DeliverAI"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation.map(item => (
                              <li key={item?.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-blue-700 text-white'
                                      : 'text-blue-200 hover:text-white hover:bg-blue-700',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? 'text-white'
                                        : 'text-blue-200 group-hover:text-white',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item?.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <a
                            href={AppRoutes.settings}
                            className={`${onSettings && 'bg-blue-700 text-white'} group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-blue-200 hover:bg-blue-700 hover:text-white hover:cursor-pointer`}
                          >
                            <Cog6ToothIcon
                              className={`${onSettings && 'text-white'} h-6 w-6 shrink-0 text-blue-200 group-hover:text-white`}
                              aria-hidden="true"
                            />
                            Settings
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-blue-600 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto cursor-pointer"
                src={'/images/logo-white.svg'}
                alt="DeliverAI"
                onClick={() => navigate('/dashboard')}
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map(item => (
                      <li key={item?.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-blue-700 text-white'
                              : 'text-blue-200 hover:text-white hover:bg-blue-700',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? 'text-white'
                                : 'text-blue-200 group-hover:text-white',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a
                    href={AppRoutes.settings}
                    className={`${onSettings && 'bg-blue-700 text-white'} group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-blue-200 hover:bg-blue-700 hover:text-white hover:cursor-pointer`}
                  >
                    <Cog6ToothIcon
                      className={`${onSettings && 'text-white'} h-6 w-6 shrink-0 text-blue-200 group-hover:text-white`}
                      aria-hidden="true"
                    />
                    Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <button
                type="button"
                className="p-2.5 text-gray-700"
                onClick={() => navigate(-1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                  />
                </svg>
              </button>
              {ifSearch && (
                <form className="relative flex flex-1">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                    onChange={e => searchQueryOnChange(e.target.value)}
                    value={searchQuery}
                  />
                </form>
              )}
              {!ifSearch && !ifSecondary && (
                <div className="relative flex flex-1"></div>
              )}
              {ifSecondary && (
                <div className="relative flex flex-1">
                  {/* Secondary navigation */}
                  <nav className="flex overflow-x-auto py-4">
                    <ul className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8">
                      {secondaryNavigation.map(item => (
                        <li
                          key={item?.name}
                          className={'flex items-center justify-center'}
                        >
                          <a
                            href={item.href}
                            className={item.current ? 'text-blue-500' : ''}
                          >
                            {item?.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              )}
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {/*{user?.firstName} {user?.lastName}*/}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map(item => (
                        <Menu.Item key={item?.name}>
                          {({ active }) => (
                            <button
                              onClick={item.onClick}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              {item?.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default DashboardLayout
