import React from 'react'
import { useNotifications } from '../../contexts/notificationContext'
import SuccessNotification from './SuccessNotification'

const NotificationComponent: React.FC = () => {
  const { notifications, removeNotification } = useNotifications()

  return (
    <>
      {notifications.map((notification, i) => {
        let title = ''
        if (notification.type === 'success') {
          title = 'Success!'
        } else if (notification.type === 'error') {
          title = 'Error! Try again.'
        } else if (notification.type === 'warning') {
          title = `Warning! ${notification.callback ? 'Click for more info.' : ''}`
        } else if (notification.type === 'info') {
          title = 'Info!'
        }

        return (
          <SuccessNotification
            key={notification.id}
            show={true}
            onClose={() => removeNotification(notification.id)}
            message={notification.message}
            type={notification.type}
            title={title}
            total={notifications.length}
            i={i}
            callback={notification.callback}
          />
        )
      })}
    </>
  )
}

export default NotificationComponent
