import React from 'react'

export default function BillingSettings() {
  return (
    <div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Billing
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Please contact care@mail.deliverai.io for billing inquiries.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
