import SearchContext from '../contexts/searchContext'
import { useContext } from 'react'

export default function useSearchContext() {
  const contextValues = useContext(SearchContext)

  if (!contextValues)
    throw new Error(
      'useSearchContext must be used within a SearchContextProvider wrapped around the App'
    )

  return contextValues
}
