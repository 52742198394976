import ProductsTable from '../components/Tables/ProductsTable'
import { ProductContextProvider } from '../contexts/productContext'

export default function Products() {
  return (
    <ProductContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <ProductsTable />
      </div>
    </ProductContextProvider>
  )
}
