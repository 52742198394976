import React from 'react'
import Loading from '../Loading'

// Table Component
type TableProps<T> = {
  data: T[]
  loading?: boolean
  children: React.ReactNode
  onAdd?: () => void
  title?: string
  description?: string
}

export function Table<T>({
  data,
  loading = false,
  children,
  onAdd,
  title,
  description,
}: TableProps<T>) {
  if (loading) {
    return <Loading full={true} />
  }

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {title}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{description}</p>
        </div>
        {onAdd && (
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={onAdd}
            >
              New
            </button>
          </div>
        )}
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  {React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                      return (
                        <th className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                          {child.props.title}
                        </th>
                      )
                    }
                    return null
                  })}
                </tr>
              </thead>
              <tbody>
                {data.length != 0 &&
                  data.map((item, index) => (
                    <tr key={index}>
                      {React.Children.map(children, child => {
                        if (React.isValidElement(child)) {
                          // Check if child.props.children is a function and call it if true
                          const content =
                            typeof child.props.children === 'function'
                              ? child.props.children(item)
                              : child.props.children

                          return (
                            <td className="border-b border-gray-200 px-3 py-3 text-sm text-gray-900">
                              {content}
                            </td>
                          )
                        }
                        return null
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
            {data.length === 0 && (
              <div className="text-center mt-8">
                <p className="text-sm text-gray-700">No data available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

// Column Component
type ColumnProps<T> = {
  title: string
  children: (item: T) => React.ReactNode // Ensure children is a function returning ReactNode
}

export function Column<T>({ children }: ColumnProps<T>) {
  return <>{children}</>
}

type TableButtonProps = {
  onClick: () => void
  srValue?: string
  title?: string
  deleteButton?: boolean
  editButton?: boolean
}

export function TableButton({
  onClick,
  srValue,
  title,
  deleteButton = false,
  editButton = false,
}: TableButtonProps) {
  let button
  if (title) {
    button = title
  } else if (deleteButton)
    button = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="red"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
    )
  else if (editButton) {
    button = 'Edit'
  }

  return (
    <button
      onClick={onClick}
      className="text-blue-600 hover:text-blue-900 font-semibold"
    >
      {button}
      <span className="sr-only">, {srValue}</span>
    </button>
  )
}
