import { createContext, PropsWithChildren, useCallback, useMemo } from 'react'
import { Person } from '../types/Person'
import { useNavigate } from 'react-router-dom'
import { useUsermaven } from '@usermaven/react'
import useSearchContext from '../hooks/useSearchContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNotifications } from './notificationContext'
import { DAIRequest } from '../utils/DAIRequest'

type contactContext = {
  loading: boolean
  people: Person[]
  onNameClick: (id: string) => void
  createContact: (data: Person) => void
  editContact: (data: Person) => void
  deleteContact: (id: string) => void
}

const ContactContext = createContext<contactContext | null>(null)

export const ContactContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const { track } = useUsermaven()
  const { searchQuery } = useSearchContext()
  const { addNotification } = useNotifications()

  const {
    isLoading: loading,
    data: peopleState,
    refetch,
  } = useQuery<Person[]>({
    queryKey: ['contacts'],
    queryFn: async () =>
      DAIRequest('GET', '/api/v1/contacts/').then(res => res.data),
    initialData: [],
  })

  const people = useMemo(() => {
    if (!searchQuery) {
      return peopleState
    }
    return peopleState?.filter(person => {
      return (
        person.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        person.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        person.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        person.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase())
      )
    })
  }, [peopleState, searchQuery])

  const onNameClick = useCallback(
    (id: string) => {
      navigate(`/dashboard/contacts/profile/${id}`)
    },
    [navigate]
  )

  const createContactMutation = useMutation({
    mutationFn: (newContact: Person) =>
      DAIRequest('POST', '/api/v1/contacts/createContact', {
        body: newContact,
      }),
    onSuccess: async () => {
      await track('Contact_Created')
      addNotification('success', 'Contact Created')
      await refetch()
    },
    onError: async () => {
      await track('Contact_Create_Error')
      addNotification('error', 'Error creating contact')
    },
  })

  const editContactMutation = useMutation({
    mutationFn: (updatedContact: Person) =>
      DAIRequest('PATCH', '/api/v1/contacts/updateContact/:id', {
        params: { id: updatedContact.id },
        body: updatedContact,
      }),
    onSuccess: async () => {
      await track('Contact_Edited')
      addNotification('success', 'Contact Edited')
      await refetch()
    },
    onError: async () => {
      await track('Contact_Edit_Error')
      addNotification('error', 'Error editing contact')
    },
  })

  const deleteContactMutation = useMutation({
    mutationFn: (id: string) =>
      DAIRequest('DELETE', '/api/v1/contacts/:id', {
        params: { id },
      }),
    onSuccess: async () => {
      await track('Contact_Deleted')
      addNotification('success', 'Contact Deleted')
      await refetch()
    },
    onError: async () => {
      await track('Contact_Delete_Error')
      addNotification('error', 'Error deleting contact')
    },
  })

  const editContact = useCallback(
    (data: Person) => {
      editContactMutation.mutate(data)
    },
    [editContactMutation]
  )

  const createContact = useCallback(
    (data: Person) => {
      createContactMutation.mutate(data)
    },
    [createContactMutation]
  )

  const deleteContact = useCallback(
    (id: string) => {
      deleteContactMutation.mutate(id)
    },
    [deleteContactMutation]
  )

  return (
    <ContactContext.Provider
      value={{
        loading,
        people,
        onNameClick,
        createContact,
        editContact,
        deleteContact,
      }}
    >
      {children}
    </ContactContext.Provider>
  )
}

export default ContactContext
