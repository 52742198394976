import { createContext, PropsWithChildren, useCallback, useMemo } from 'react'
import { Sale } from '../types/Sale'
import { useUsermaven } from '@usermaven/react'
import { PersonSimple } from '../types/Person'
import { ProductSimple } from '../types/Product'
import useSearchContext from '../hooks/useSearchContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { DAIRequest } from '../utils/DAIRequest'
import { useNotifications } from './notificationContext'
import { useNavigate } from 'react-router-dom'

type SaleContextType = {
  loading: boolean
  sales: Sale[]
  createSale: (data: Sale) => void
  editSale: (data: Sale) => void
  contacts: PersonSimple[]
  products: ProductSimple[]
  onNameClick: (id: string) => void
  deleteSale: (id: string) => void
}

const SaleContext = createContext<SaleContextType | undefined>(undefined)

export const SaleContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const { track } = useUsermaven()
  const { searchQuery } = useSearchContext()
  const { addNotification } = useNotifications()

  const onNameClick = useCallback(
    (id: string) => {
      navigate(`/dashboard/contacts/profile/${id}`)
    },
    [navigate]
  )

  const {
    data: salesState,
    isLoading: salesLoading,
    refetch,
  } = useQuery<Sale[]>({
    queryKey: ['sales'],
    queryFn: async () => {
      return (await DAIRequest('get', '/api/v1/purchases/getAllMyPurchases'))
        .data
    },
  })

  const sales = useMemo(() => {
    if (!searchQuery) {
      return salesState
    }
    return salesState?.filter(product =>
      product.contact.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [salesState, searchQuery])

  const { data: contacts, isLoading: contactsLoading } = useQuery<
    PersonSimple[]
  >({
    queryKey: ['contact', 'basic'],
    queryFn: async () => {
      return (await DAIRequest('get', '/api/v1/contacts/basic')).data
    },
  })

  const { data: products, isLoading: productsLoading } = useQuery<
    ProductSimple[]
  >({
    queryKey: ['product-categories', 'basic'],
    queryFn: async () => {
      return (
        await DAIRequest(
          'get',
          '/api/v1/product-categories/getMyProductCategoriesBasic'
        )
      ).data
    },
  })

  const createSale = useMutation({
    mutationFn: async (data: Sale) => {
      return await DAIRequest('post', '/api/v1/purchases/createPurchase', {
        body: data,
      })
    },
    onSuccess: async () => {
      await track('Sale_Created')
      await refetch()
      addNotification('success', 'Sale created successfully')
    },
    onError: () => {
      addNotification('error', 'Error creating sale')
    },
  })

  const editSale = useMutation({
    mutationFn: async (data: Sale) => {
      return await DAIRequest('patch', '/api/v1/purchases/updatePurchase/:id', {
        body: data,
        params: { id: data.id },
      })
    },
    onSuccess: async () => {
      await track('Sale_Edited')
      await refetch()
      addNotification('success', 'Sale edited successfully')
    },
    onError: async () => {
      await track('Sale_Edited_Error')
      addNotification('error', 'Error editing sale')
    },
  })

  const deleteSale = useMutation({
    mutationFn: async (id: string) => {
      return await DAIRequest('delete', '/api/v1/purchases/:id', {
        params: { id },
      })
    },
    onSuccess: async () => {
      await track('Sale_Deleted')
      await refetch()
      addNotification('success', 'Sale deleted successfully')
    },
    onError: async () => {
      await track('Sale_Deleted_Error')
      addNotification('error', 'Error deleting sale')
    },
  })

  const loading = useMemo(() => {
    return salesLoading || contactsLoading || productsLoading
  }, [salesLoading, contactsLoading, productsLoading])

  return (
    <SaleContext.Provider
      value={{
        loading,
        createSale: createSale.mutate,
        editSale: editSale.mutate,
        sales: sales ?? [],
        contacts: contacts ?? [],
        products: products ?? [],
        deleteSale: deleteSale.mutate,
        onNameClick,
      }}
    >
      {children}
    </SaleContext.Provider>
  )
}

export default SaleContext
