import { Dispatch, Fragment, SetStateAction, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { Note } from '../../types/Note'
import TextBox from '../InputBoxes/TextBox'
import useProfileContext from '../../hooks/useProfileContext'
import { CreateNote, UpdateNote } from '../../contexts/profileContext'
import { useParams } from 'react-router-dom'

export default function CreateNotesModal(props: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  initialData?: Note
  onSubmit: () => void
}) {
  const { id } = useParams()
  const { createNoteMutation, updateNoteMutation, deleteNoteMutation } =
    useProfileContext()
  const cancelButtonRef = useRef(null)
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<Note>({
    defaultValues: props.initialData,
  })

  const onSubmit = (data: Note) => {
    if (props.initialData) {
      const updateNote: UpdateNote = {
        id: props.initialData?.id!,
        content: data.content,
        contactId: id!,
      }
      updateNoteMutation.mutate(updateNote)
    } else {
      const createData: CreateNote = {
        content: data.content,
        contactId: id!,
      }
      createNoteMutation.mutate(createData)
    }

    props.setOpen(false)
  }

  const handleCancel = () => {
    props.setOpen(false)
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="h-96 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-xl lg:max-w-2xl xl:max-w-3xl sm:p-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextBox
                    registerDescription={register('content')}
                    handleCancel={handleCancel}
                    enableTitle={false}
                  />
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
