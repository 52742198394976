import ResuableSidePanel from './ResuableSidePanel'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import CustomCombo from '../Dropdowns/CustomCombo'
import { Box } from '../../pages/CreateAutomation'
import useAutomationContext from '../../hooks/useAutomationContext'

type NamedEntity = {
  id: string
  name: string
}

interface AutomationForm {
  title: string
  description: string
  automationType: NamedEntity
  action: NamedEntity
  event: NamedEntity
  productType?: NamedEntity
  template?: NamedEntity
  when?: NamedEntity
  whenDay?: number
}

const automationTypes = [
  {
    id: '1',
    name: 'When an event occurs',
  },
]

const actions = [
  {
    id: '1',
    name: 'Communication - Send Email',
  },
  {
    id: '2',
    name: 'Communication - Send Text',
  },
]

const templates = [
  {
    id: '1',
    name: 'Anniversary Email Reminder Template',
  },
  {
    id: '2',
    name: 'Template 2',
  },
]

const productTypes = [
  {
    id: '1',
    name: 'Ring',
  },
  {
    id: '2',
    name: 'Bracelet',
  },
]

const when = [
  {
    id: '1',
    name: 'Before',
  },
  {
    id: '2',
    name: 'After',
  },
]

export default function CreateAutomationSidePanel(props: {
  onClose: () => void
  addBox?: (box: Box) => void
  updateBox?: (box: Box) => void
  addConnection?: (from: number, to: number, label: string) => void
  setAutomationTitle?: (title: string) => void
}) {
  const [open, setOpen] = useState(true)
  const { triggers } = useAutomationContext()

  const { control, getValues, setValue, watch, register, formState } =
    useForm<AutomationForm>({})

  const selectedAction = watch('action')
  const selectedAutomationType = watch('automationType')
  const selectedEventType = watch('event')

  const handleOnClose = useCallback(() => {
    props.onClose()
  }, [])

  return (
    <>
      <ResuableSidePanel
        title={'Create Automation'}
        open={open}
        onClose={handleOnClose}
      >
        <form className="flex h-full flex-col divide-y divide-gray-200 bg-white">
          <div className="h-0 flex-1 overflow-y-auto pb-16">
            <div className="flex flex-1 flex-col justify-between">
              <div className="divide-y divide-gray-200 px-2">
                <div className="space-y-6">
                  <div>
                    <label
                      htmlFor="automation-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Automation Name
                    </label>
                    <div className="mt-2">
                      <input
                        placeholder="Title"
                        type="text"
                        id="automation-name"
                        {...register('title')}
                        onChange={e => {
                          props?.setAutomationTitle?.(e.target.value)
                          setValue('title', e.target.value)
                        }}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name="automationType"
                      render={({ field }) => (
                        <CustomCombo
                          label={'Automation Type'}
                          data={automationTypes}
                          {...field}
                          ref={null}
                        />
                      )}
                    />
                  </div>
                  {selectedAutomationType?.id === '1' && (
                    <div>
                      <Controller
                        control={control}
                        name="event"
                        render={({ field }) => (
                          <CustomCombo
                            label={'Event'}
                            data={triggers}
                            {...field}
                            ref={null}
                          />
                        )}
                      />
                    </div>
                  )}
                  {selectedAutomationType?.id === '1' && (
                    <>
                      <div className="flex flex-row">
                        <Controller
                          control={control}
                          name="when"
                          render={({ field }) => (
                            <CustomCombo
                              label="When"
                              data={when}
                              {...field}
                              ref={null}
                            />
                          )}
                        />
                        <div className="ml-4">
                          <label
                            htmlFor="automation-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            When Day
                          </label>
                          <div className="mt-2">
                            <input
                              id="when-day"
                              type="number"
                              {...register('whenDay')}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {selectedEventType?.id === '4' && (
                    <div>
                      <Controller
                        control={control}
                        name="productType"
                        render={({ field }) => (
                          <CustomCombo
                            label={'Product Type'}
                            data={productTypes}
                            {...field}
                            ref={null}
                          />
                        )}
                      />
                    </div>
                  )}
                  <div>
                    <Controller
                      control={control}
                      name="action"
                      render={({ field }) => (
                        <CustomCombo
                          label={'Action'}
                          data={actions}
                          {...field}
                          ref={null}
                        />
                      )}
                    />
                  </div>
                  {selectedAction?.id === '1' && (
                    <div>
                      <Controller
                        control={control}
                        name="template"
                        render={({ field }) => (
                          <CustomCombo
                            label={'Template'}
                            data={templates}
                            {...field}
                            ref={null}
                          />
                        )}
                      />
                    </div>
                  )}
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Description
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="description"
                        rows={4}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        defaultValue={''}
                        {...register('description')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-shrink-0 justify-end px-4 py-4">
            <button
              type="button"
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-4 inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Save
            </button>
          </div>
        </form>
      </ResuableSidePanel>
    </>
  )
}
