import { useContext } from 'react'
import ContactContext from '../contexts/contactContext'

export default function useContactContext() {
  const contextValues = useContext(ContactContext)

  if (!contextValues)
    throw new Error(
      'useContactContext must be used within a ContactsContextProvider wrapped around the App'
    )

  return contextValues
}
