import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useAuthContext from '../hooks/useAuthContext'
import { AppRoutes } from '../utils/constants'

export default function ForgotPassword() {
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm<any>()

  const goBack = useCallback(() => {
    navigate(AppRoutes.login)
  }, [])

  return (
    <>
      <div className="flex min-h-screen flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-14 w-auto"
                src={'/images/logo.svg'}
                alt="DeliverAI"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Reset your password
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                <a
                  onClick={goBack}
                  className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                >
                  Go back to login
                </a>
              </p>
            </div>
            <div className="mt-10">
              <div>
                <form onSubmit={() => {}} className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <p className="mt-1 text-sm leading-6 text-gray-400 pb-4">
                      Enter the email address associated with your account, and
                      we’ll email you a link to reset your password.
                    </p>
                    <div className="mt-2">
                      <input
                        id="email"
                        type="email"
                        {...register('email')}
                        autoComplete="email"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <div className="image-container">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="/images/Jewelry_Store.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  )
}
