import { createContext, PropsWithChildren, useState } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { AppRoutes } from '../utils/constants'
import { useNavigate } from 'react-router-dom'
import { useUsermaven } from '@usermaven/react'
import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query'
import { useNotifications } from './notificationContext'
import { DAIRequest } from '../utils/DAIRequest'

export type User = {
  id: string
  firstName: string
  lastName: string
  profilePicture: string
  email: string
  role: string
  organizationId: string
  timezone: string
}

export type LoginType = {
  email: string
  password: string
  rememberMe: boolean
}

type AuthContextType = {
  isAuthenticated: boolean
  isLoading: boolean
  user?: User
  logout: () => void
  loginMutation: UseMutationResult<
    AxiosResponse<any>,
    unknown,
    LoginType,
    unknown
  >
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const { addNotification, removeAllNotifications } = useNotifications()
  const { id } = useUsermaven()

  // Define the function that fetches user data
  const {
    isLoading,
    data: user,
    refetch,
  } = useQuery<User, Error>({
    queryKey: ['user'],
    queryFn: async () => {
      const response = await DAIRequest('get', '/api/v1/users/me')
      setIsAuthenticated(true)
      return response.data
    },
    retry: false,
  })

  const logout = async () => {
    localStorage.removeItem('token')
    setIsAuthenticated(false)
    removeAllNotifications()
    addNotification('success', 'Logged out successfully')
    navigate(AppRoutes.login)
    await refetch()
  }

  const loginMutation = useMutation({
    mutationFn: async (data: LoginType) => {
      return await DAIRequest('post', '/api/v1/authentication/login', {
        body: data,
      })
    },
    onSuccess: async res => {
      localStorage.setItem('token', res.data.token)
      axios.defaults.headers.common['Authorization'] =
        `Bearer ${res.data.token}`
      addNotification('success', 'Login successful')
      setIsAuthenticated(true)
      navigate(AppRoutes.home)
      await id({ id: res.data.organizationId, email: res.data.email })
      await refetch()
    },
    onError: (err: AxiosError) => {
      setIsAuthenticated(false)
      if (err.response?.status === 401) {
        return addNotification('error', 'Incorrect Password')
      }
      addNotification('error', 'Login failed')
    },
  })

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        logout,
        loginMutation,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
