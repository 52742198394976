import { createContext, PropsWithChildren, useState } from 'react'
import { AutomationComplex } from '../types/Automation'
import { useQuery } from '@tanstack/react-query'
import { DAIRequest } from '../utils/DAIRequest'

type AutomationContextType = {
  loading: boolean
  automations: AutomationComplex[]
  triggers: Trigger[]
}

const AutomationContext = createContext<AutomationContextType | undefined>(
  undefined
)

type Trigger = {
  id: string
  name: string
}

export const AutomationContextProvider = ({ children }: PropsWithChildren) => {
  const { data: automations, isLoading: loading } = useQuery<
    AutomationComplex[]
  >({
    queryKey: ['automations'],
    queryFn: async () => {
      return (await DAIRequest('GET', '/api/v1/automations/getAutomations'))
        .data.automations
    },
  })

  const { data: triggers, isLoading: loadingTriggers } = useQuery<Trigger[]>({
    queryKey: ['triggers'],
    queryFn: async () => {
      return (await DAIRequest('GET', '/api/v1/triggers/getTriggers')).data
    },
  })

  return (
    <AutomationContext.Provider
      value={{
        loading,
        automations: automations ?? [],
        triggers: triggers ?? [],
      }}
    >
      {children}
    </AutomationContext.Provider>
  )
}

export default AutomationContext
