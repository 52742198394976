const API_URL = process.env.REACT_APP_API_URL!

const AppRoutes = {
  root: '/',
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  schedule: '/schedule',
  contacts: '/dashboard/contacts',
  appointments: '/dashboard/appointments',
  products: '/dashboard/products',
  sales: '/dashboard/sales',
  templates: '/dashboard/templates',
  automations: '/dashboard/automations',
  editTemplateRoot: '/dashboard/templates/edit',
  editTemplate: '/dashboard/templates/edit/:id',
  updateAutomationRoot: '/dashboard/automations/update',
  updateAutomation: '/dashboard/automations/update/:id',
  contactProfile: '/dashboard/contacts/profile/:id',
  settings: '/dashboard/settings',
  integrationSettings: '/dashboard/settings/integrations',
  teamSettings: '/dashboard/settings/team',
  billing: '/dashboard/settings/billing',
  home: '/dashboard',
  websiteBuilder: '/dashboard/website-builder',
  notFound: '*',
}

const timezones = [
  { timezone: 'Pacific/Midway', offset: 'UTC-11:00' },
  { timezone: 'Pacific/Honolulu', offset: 'UTC-10:00' },
  { timezone: 'America/Juneau', offset: 'UTC-09:00' },
  { timezone: 'America/Los_Angeles', offset: 'UTC-08:00' },
  { timezone: 'America/Denver', offset: 'UTC-07:00' },
  { timezone: 'America/Chicago', offset: 'UTC-06:00' },
  { timezone: 'America/New_York', offset: 'UTC-05:00' },
  { timezone: 'America/Caracas', offset: 'UTC-04:00' },
  { timezone: 'America/Sao_Paulo', offset: 'UTC-03:00' },
  { timezone: 'Atlantic/Azores', offset: 'UTC-01:00' },
  { timezone: 'Europe/London', offset: 'UTC+00:00' },
  { timezone: 'Europe/Berlin', offset: 'UTC+01:00' },
  { timezone: 'Europe/Moscow', offset: 'UTC+03:00' },
  { timezone: 'Asia/Dubai', offset: 'UTC+04:00' },
  { timezone: 'Asia/Karachi', offset: 'UTC+05:00' },
  { timezone: 'Asia/Kolkata', offset: 'UTC+05:30' },
  { timezone: 'Asia/Dhaka', offset: 'UTC+06:00' },
  { timezone: 'Asia/Bangkok', offset: 'UTC+07:00' },
  { timezone: 'Asia/Hong_Kong', offset: 'UTC+08:00' },
  { timezone: 'Asia/Tokyo', offset: 'UTC+09:00' },
  { timezone: 'Australia/Sydney', offset: 'UTC+10:00' },
  { timezone: 'Pacific/Fiji', offset: 'UTC+12:00' },
]

export { API_URL, AppRoutes, timezones }
