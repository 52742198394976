import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, useCallback, useState } from 'react'
import { classNames } from '../utils/className'
import CalendarDayView from './CalendarDayView'
import CalendarWeekView from './CalendarWeekView'
import CalendarMonthView from './CalendarMonthView'
import SchedulerEditor from './NylasComponents/SchedulerEditor'
import CreateScheduler from './NylasComponents/CreateScheduler'

export default function Calendar() {
  const [currentCalendar, setCurrentCalendar] = useState<
    'day' | 'week' | 'month'
  >('day')
  const [editSchedulerShown, setEditSchedulerShown] = useState(false)
  const [createSchedulerShown, setCreateEditSchedulerShown] = useState(false)

  const openScheduler = useCallback(() => {
    setEditSchedulerShown(true)
  }, [setEditSchedulerShown])

  const closeScheduler = useCallback(() => {
    setEditSchedulerShown(false)
  }, [setEditSchedulerShown])

  const openCreateScheduler = useCallback(() => {
    setCreateEditSchedulerShown(true)
  }, [setCreateEditSchedulerShown])

  const closeCreateScheduler = useCallback(() => {
    setCreateEditSchedulerShown(false)
  }, [setCreateEditSchedulerShown])

  return (
    <>
      {editSchedulerShown && (
        <SchedulerEditor
          isOpen={editSchedulerShown}
          closeModal={closeScheduler}
          openModal={openScheduler}
        />
      )}
      {createSchedulerShown && (
        <CreateScheduler
          isOpen={createSchedulerShown}
          closeModal={closeCreateScheduler}
          openModal={openCreateScheduler}
        />
      )}
      <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 pb-4">
        <div>
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            <time dateTime="2022-01-22" className="sm:hidden">
              Jan 22, 2022
            </time>
            <time dateTime="2022-01-22" className="hidden sm:inline">
              January 22, 2022
            </time>
          </h1>
          <p className="mt-1 text-sm text-gray-500">Saturday</p>
        </div>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous day</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
            >
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next day</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden md:ml-4 md:flex md:items-center">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {currentCalendar === 'day' && 'Day view'}
                {currentCalendar === 'week' && 'Week view'}
                {currentCalendar === 'month' && 'Month view'}
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => {
                            setCurrentCalendar('day')
                          }}
                          className={classNames(
                            currentCalendar === 'day'
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                          )}
                        >
                          Day view
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => {
                            setCurrentCalendar('week')
                          }}
                          className={classNames(
                            currentCalendar === 'week'
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                          )}
                        >
                          Week view
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => {
                            setCurrentCalendar('month')
                          }}
                          className={classNames(
                            currentCalendar === 'month'
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                          )}
                        >
                          Month view
                        </a>
                      )}
                    </Menu.Item>
                    {/*<Menu.Item>*/}
                    {/*  {({ active }) => (*/}
                    {/*    <a*/}
                    {/*      href="#"*/}
                    {/*      className={classNames(*/}
                    {/*        active*/}
                    {/*          ? 'bg-gray-100 text-gray-900'*/}
                    {/*          : 'text-gray-700',*/}
                    {/*        'block px-4 py-2 text-sm'*/}
                    {/*      )}*/}
                    {/*    >*/}
                    {/*      Year view*/}
                    {/*    </a>*/}
                    {/*  )}*/}
                    {/*</Menu.Item>*/}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <div className="ml-6 h-6 w-px bg-gray-300" />
            <button
              onClick={openScheduler}
              type="button"
              className="ml-6 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Edit Event Types
            </button>
            <div className="ml-6 h-6 w-px bg-gray-300" />
            <button
              onClick={openCreateScheduler}
              type="button"
              className="ml-6 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Create Event
            </button>
          </div>
          <Menu as="div" className="relative ml-6 md:hidden">
            <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open menu</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        Edit Event Types
                      </a>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        Create event
                      </a>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => {
                          setCurrentCalendar('day')
                        }}
                        className={classNames(
                          currentCalendar === 'day'
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        Day view
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => {
                          setCurrentCalendar('week')
                        }}
                        className={classNames(
                          currentCalendar === 'week'
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        Week view
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => {
                          setCurrentCalendar('month')
                        }}
                        className={classNames(
                          currentCalendar === 'month'
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        Month view
                      </a>
                    )}
                  </Menu.Item>
                  {/*<Menu.Item>*/}
                  {/*  {({ active }) => (*/}
                  {/*    <a*/}
                  {/*      href="#"*/}
                  {/*      className={classNames(*/}
                  {/*        active*/}
                  {/*          ? 'bg-gray-100 text-gray-900'*/}
                  {/*          : 'text-gray-700',*/}
                  {/*        'block px-4 py-2 text-sm'*/}
                  {/*      )}*/}
                  {/*    >*/}
                  {/*      Year view*/}
                  {/*    </a>*/}
                  {/*  )}*/}
                  {/*</Menu.Item>*/}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </header>
      {currentCalendar == 'day' && <CalendarDayView />}
      {currentCalendar == 'week' && <CalendarWeekView />}
      {currentCalendar == 'month' && <CalendarMonthView />}
    </>
  )
}
