import { Fragment, useCallback, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Controller, useForm } from 'react-hook-form'
import {
  formatISOToLocalDatetime,
  getTodayDateStringForUserTimezone,
} from '../../utils/timezone'
import CustomCombo from '../Dropdowns/CustomCombo'
import { Sale } from '../../types/Sale'
import { ModalProps } from '../../types/ModalProps'
import useProfileContext from '../../hooks/useProfileContext'

export default function CreateProfileSaleModal(props: ModalProps<Sale>) {
  const cancelButtonRef = useRef(null)
  const { createSale, editSale, products, contacts } = useProfileContext()

  const { register, handleSubmit, control } = useForm<Sale>({
    defaultValues: {
      ...props.initialData,
      createdAt:
        formatISOToLocalDatetime(props.initialData?.createdAt) ??
        getTodayDateStringForUserTimezone(),
    },
  })

  const onSubmit = useCallback(
    (data: Sale) => {
      data.cost = parseFloat(data.cost as unknown as string)
      data.createdAt = new Date(data.createdAt).toISOString()

      if (props.initialData) {
        data.id = props.initialData.id
        editSale(data)
      } else {
        createSale(data)
      }
      props.closeModal()
    },
    [createSale, editSale, props.closeModal]
  )

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        initialFocus={cancelButtonRef}
        onClose={props.closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-xl lg:max-w-2xl xl:max-w-3xl sm:p-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Sale Information
                      </h2>

                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <div className="mt-2" id={'name'}>
                            <Controller
                              control={control}
                              name="contact"
                              render={({ field }) => (
                                <CustomCombo
                                  label={'Name'}
                                  data={contacts}
                                  {...field}
                                  ref={null}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <div className="mt-2" id={'product'}>
                            <Controller
                              control={control}
                              name="productCategory"
                              render={({ field }) => (
                                <CustomCombo
                                  label={'Product'}
                                  data={products}
                                  {...field}
                                  ref={null}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="salesPrice"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Sales Price
                          </label>
                          <div className="mt-2">
                            <input
                              id="salesPrice"
                              type="number"
                              step="0.01"
                              {...register('cost')}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="invoice"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Invoice Number
                          </label>
                          <div className="mt-2">
                            <input
                              id="invoice"
                              type="text"
                              {...register('invoiceNumber')}
                              autoComplete="invoice-number"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3 sm:col-start-1">
                          <label
                            htmlFor="date"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Sales Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="datetime-local"
                              id="date"
                              {...register('createdAt')}
                              autoComplete="address-level2"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-gray-900"
                      onClick={props.closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
