import { BarsArrowUpIcon, UsersIcon } from '@heroicons/react/20/solid'
import { UseFormRegisterReturn } from 'react-hook-form'

interface NameInputProps {
  register: UseFormRegisterReturn
  placeholder?: string
}

export default function NameInput({ register, placeholder }: NameInputProps) {
  return (
    <div>
      {/*<label*/}
      {/*  htmlFor="name"*/}
      {/*  className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*>*/}
      {/*  Name*/}
      {/*</label>*/}
      <div className="mt-2 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="name"
            id="name"
            className="block w-full rounded-md rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder={placeholder}
            {...register}
          />
        </div>
      </div>
    </div>
  )
}
