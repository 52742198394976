import React, { useEffect } from 'react'
import DashboardLayout from './layout/DashboardLayout'
import { Navigate, Route, Routes } from 'react-router-dom'
import Contacts from './pages/Contacts'
import Appointments from './pages/Appointments'
import Login from './pages/Login'
import Profile from './pages/Profile'
import Sales from './pages/Sales'
import Home from './pages/Home'
import Templates from './pages/Templates'
import Products from './pages/Products'
import Automations from './pages/Automations'
import NotFound from './pages/NotFound'
import { useUsermaven } from '@usermaven/react'
import { SearchContextProvider } from './contexts/searchContext'
import Edit from './pages/Edit'
import NotificationComponent from './components/Notifications/NotificationComponent'
import NewAppointment from './pages/NewAppointment'
import { AppRoutes } from './utils/constants'
import ForgotPassword from './pages/ForgotPassword'
import IntegrationSettings from './pages/IntegrationSettings'
import Settings from './pages/Settings'
import TeamSettings from './pages/TeamSettings'
import CreateAutomation from './pages/CreateAutomation'
import { PrivateRoute } from './layout/PrivateRoute'
import Register from './pages/Register'
import BillingSettings from './pages/BillingSettings'
import { TemplateContextProvider } from './contexts/templateContext'
import WebsiteBuilder from './pages/WebsiteBuilder'
import { ProfileContextProvider } from './contexts/profileContext'

function App() {
  const { trackPageView } = useUsermaven()

  useEffect(() => {
    trackPageView()
  }, [trackPageView])

  return (
    <div className="App">
      <NotificationComponent />
      <Routes>
        <Route
          path={AppRoutes.root}
          element={<Navigate to={AppRoutes.login} />}
        />
        <Route path={AppRoutes.login} element={<Login />} />
        <Route path={AppRoutes.register} element={<Register />} />
        <Route path={AppRoutes.forgotPassword} element={<ForgotPassword />} />
        <Route path={AppRoutes.schedule} element={<NewAppointment />} />
        <Route
          element={
            <SearchContextProvider>
              <DashboardLayout />
            </SearchContextProvider>
          }
        >
          <Route element={<PrivateRoute />}>
            <Route path={AppRoutes.home} element={<Home />} />
            <Route path={AppRoutes.contacts} element={<Contacts />} />

            <Route
              path={AppRoutes.contactProfile}
              element={
                <ProfileContextProvider>
                  <Profile />
                </ProfileContextProvider>
              }
            />
            <Route path={AppRoutes.appointments} element={<Appointments />} />
            <Route path={AppRoutes.products} element={<Products />} />
            <Route path={AppRoutes.sales} element={<Sales />} />
            <Route path={AppRoutes.templates} element={<Templates />} />
            <Route
              path={AppRoutes.editTemplate}
              element={
                <TemplateContextProvider>
                  <Edit />
                </TemplateContextProvider>
              }
            />
            <Route path={AppRoutes.automations} element={<Automations />} />
            <Route
              path={AppRoutes.updateAutomation}
              element={<CreateAutomation />}
            />
            <Route path={AppRoutes.settings} element={<Settings />} />
            <Route
              path={AppRoutes.integrationSettings}
              element={<IntegrationSettings />}
            />
            <Route path={AppRoutes.teamSettings} element={<TeamSettings />} />
            <Route path={AppRoutes.billing} element={<BillingSettings />} />
            <Route
              path={AppRoutes.websiteBuilder}
              element={<WebsiteBuilder />}
            />
          </Route>
        </Route>
        <Route path={AppRoutes.notFound} element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default App
