import { createContext, PropsWithChildren, useMemo, useState } from 'react'

type searchContext = {
  searchQueryOnChange: (query: string) => void
  searchQuery: string
  dateQuery: string
  dateQueryOnChange: (query: string) => void
}

const SearchContext = createContext<searchContext | null>(null)

export const SearchContextProvider = ({ children }: PropsWithChildren) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [dateQuery, setDateQuery] = useState<string>('')

  const searchQueryOnChange = (query: string) => {
    setSearchQuery(query)
  }

  const dateQueryOnChange = (query: string) => {
    setDateQuery(query)
  }

  return (
    <SearchContext.Provider
      value={{ searchQuery, searchQueryOnChange, dateQuery, dateQueryOnChange }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export default SearchContext
