import { Fragment, useCallback, useState } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../utils/className'

type CustomComboProps = {
  data: { id: string; name: string }[]
  value?: { id: string; name: string }
  onChange: (value: { id: string; name: string }) => void
  ref?: any
  label?: string
  searchable?: boolean
}

function CustomCombo({
  data,
  value,
  onChange,
  label,
  searchable = true,
}: CustomComboProps) {
  const people = data
  const [selected, setSelected] = useState(value || { id: '0', name: '' })
  const [query, setQuery] = useState('')

  const onChangeHandler = useCallback(
    (value: { id: string; name: string }) => {
      setSelected(value)
      onChange(value)
    },
    [onChange]
  )

  const filteredPeople =
    query === ''
      ? people
      : people.filter(person =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  return (
    <div className="w-full">
      <Combobox as="div" value={selected} onChange={onChangeHandler}>
        {label && (
          <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
            {label}
          </Combobox.Label>
        )}
        <div className="relative mt-2">
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            onChange={event => setQuery(event.target.value)}
            autoComplete={'off'}
            displayValue={person => {
              if (!person) return ''
              return (person as { id: string; name: string }).name
            }}
            disabled={!searchable}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredPeople.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.map(person => (
                <Combobox.Option
                  key={person.id}
                  value={person}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-blue-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          'block truncate',
                          selected ? 'font-semibold' : 'font-normal'
                        )}
                      >
                        {person.name}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-blue-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  )
}

export default CustomCombo
